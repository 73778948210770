<template>
  <vx-card no-shadow>
    <h2 class="text-primary">{{ memberData.member_username }}</h2>
    <vs-list>
      <vs-list-header title="Credit" icon="monetization_on"></vs-list-header>
      <p class="text-danger">
        ยอดเงิน :
        <a class="text-success">{{
          currency(parseFloat(memberData.member_balance).toFixed(2))
        }}
          บาท</a>
      </p>
      <p class="text-danger">
        ยอดลิมิตถอน :
        {{
          currency(parseFloat(memberData.member_limitwithdraw).toFixed(2))
        }}
        บาท
      </p>
      <p v-if="memberData.member_level == 3" class="text-danger">
        สถานะลิมิตถอน : ติดสถานะลิมิตถอน
      </p>
      <p v-else class="text-success">
        สถานะลิมิตถอน : ไม่ติดสถานะอั้นถอน
      </p>
      <p v-if="memberData.member_play_fish == false" class="text-danger">
        สถานะยิงปลา : <a class="text-success">ไม่ได้อยู่ในเกม</a>
      </p>
      <p v-if="memberData.member_play_fish == true" class="text-success">
        สถานะยิงปลา : <a class="text-success">อยู่ในเกมยิงปลา</a>
      </p>
      <p v-if="memberData.member_play_lotto == false" class="text-danger">
        สถานะหวย : <a class="text-success">ไม่อยู่ในเกม</a>
      </p>
      <p v-if="memberData.member_play_lotto == true" class="text-success">
        สถานะหวย : <a class="text-success">อยู่ในเกม</a>
      </p>
      <p v-if="memberData.member_bonus == 0" class="text-success">
        โบนัสล่าสุดที่ลูกค้ารับ : ไม่รับโบนัส
      </p>
      <p v-else-if="memberBonusInfo" class="text-danger">
        โบนัสล่าสุดที่ลูกค้ารับ : {{ memberBonusInfo.bonus_name }}
      </p>
      <p v-if="memberData.member_bonus_type == 0" class="text-success">
        ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success">ไม่มี</a>
      </p>
      <p v-else-if="memberData.member_bonus_type == 1" class="text-danger">
        ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success">CASINO</a>
      </p>
      <p v-else-if="memberData.member_bonus_type == 2" class="text-danger">
        ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success">SLOT</a>
      </p>
      <p v-else-if="memberData.member_bonus_type == 3" class="text-danger">
        ประเภทโบนัสล่าสุดที่ลูกค้ารับ : <a class="text-success">SPORT</a>
      </p>
      <p v-if="memberData.member_kyc== false" class="text-danger">
        สถานะ KYC : <a class="text-danger">ยังไม่ยืนยัน</a>
      </p>
      <p v-if="memberData.member_kyc== true" class="text-danger">
        สถานะ KYC : <a class="text-success">ยืนยันแล้ว</a>
      </p>
      <p v-if="title_name == 'SLOTHENG'" class="text-danger">
        ย้ายข้อมูลมาจาก :
        <a class="text-success">{{ memberData.member_trans_from }}</a>
      </p>
      <p>
        หมายเหตุ : {{ commentData[0].comment }}
      </p>
      <vs-divider />
      <p>ระดับ Ranking : VIP {{ memberData.ranking_level }}</p>
      <p>
        ยอดเทิร์นโอเวอร์ Ranking :
        {{ currency(parseFloat(memberData.member_sum_turnranking).toFixed(2)) }}
      </p>
      <vs-divider />
      <p>
        ยอดเทิร์นโอเวอร์ที่ลูกค้าทำปัจจุบัน :
        {{ currency(parseFloat(memberData.member_sum_turnover).toFixed(2)) }}
      </p>
      <p>
        ยอดเทิร์นโอเวอร์ขั้นต่ำที่ต้องทำ :
        {{ currency(parseFloat(memberData.member_turnover).toFixed(2)) }}
      </p>
      <p>
        ยอดคืนยอดเล่น :
        {{ currency(parseFloat(memberData.member_turn_point).toFixed(2)) }}
      </p>
      <p>
        ยอดแนะนำเพื่อน :
        {{ currency(parseFloat(memberData.member_aff_point).toFixed(2)) }}
      </p>
      <vs-divider />
      <!-- <h4> กิจกรรมพิเศษ </h4>
      <p v-if="memberData.member_tour_join == false" class="text-danger">ลงทะเบียนเกาจิ้ง : <a class="text-success" >ยังไม่ลงทะเบียน</a>  </p>
      <p v-if="memberData.member_tour_join == true" class="text-success">ลงทะเบียนเกาจิ้ง : <a class="text-success" >ลงทะเบียนแล้ว</a>  </p>
      <p>ยอดได้เสียเกาจิ้งเมืองไทย : {{currency(parseFloat(memberData.member_tour_winloss).toFixed(2))}}</p>
      <p>ยอดเทิร์นโอเวอร์ เกาจิ้งเมืองไทย : {{currency(parseFloat(memberData.member_tour_turnover).toFixed(2))}}</p>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small" icon-pack="feather" icon="icon-edit" color="#AAAD3E"
        @click="callOtppopup()"> ลงทะเบียนเกาจิ้งเมืองไทย</vs-button>
      <vs-divider /> -->

      <!-- god buttons -->
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20 mt-10"
        size="small" icon-pack="feather" icon="icon-edit" color="danger" @click="popupplayfish()">
        ปุ่มแก้สถานะยิงปลา</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="#cc99ff" @click="popupplaylotto()"> ปุ่มแก้สถานะหวย</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="success" @click="syncline()"> ยกเลิกเชื่อมต่อไลน์</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="warning" @click="editWinLose()"> ปรับยอดเสียสะสม</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="danger" @click="updateaff()"> ปุ่มอัพเดทรายชื่อแนะนำเพื่อน</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="#454545" @click="updatebonus()"> แก้ไขโบนัสลูกค้า</vs-button>
      <br>
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small"
        icon-pack="feather" icon="icon-edit" color="#9932CC" @click="popupClearCard()"> ล้างสถานะเปิดไพ่</vs-button>
      <br>
      <!-- #CANCEL_SYSTEM -->
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20"
        size="small" icon-pack="feather" icon="icon-edit" color="#D18781" @click="openRefundPopup()"> แก้ไขรายการติด BET
        ทั้งหมด</vs-button>
      <br />
      <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true" class="mr-20" size="small" 
      icon-pack="feather" icon="icon-edit" color="danger" @click="popupkyc()">
      ปุ่มยืนยัน KYC</vs-button>
      <br>
      <vs-popup title="โปรดยืนยันการคืนเงินติด BET ทั้งหมด" :active.sync="refundPopupSync">
        <div class="p-2 px-3">
          <h2 class="text-success my-4">
            {{ memberData.member_username }}
          </h2>

          <div class="my-4">
            <h2 class="vs-input--label mb-2">
              โปรดเลือกเกมที่ต้องการแก้ไขรายการ BET
            </h2>
            <vs-select class="w-full mt-1 " v-model="refundGame">
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                v-for="(item, index) in refundGameList" />
            </vs-select>
          </div>

          <div class="text-center">
            <!-- แก้ไขรายการ -->
            <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action
                ? false
                : true
              " @click="() => refundBet(false)" type="filled" size="small" color="success" icon-pack="feather"
              class="mr-2 my-3">แก้ไขรายการ
            </vs-button>
            <!-- แก้ไขรายการและคืนเงิน -->
            <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action
                ? false
                : true
              " @click="() => refundBet(true)" type="filled" size="small" color="warning" icon-pack="feather"
              class="mr-2 my-3">แก้ไขรายการและคืนเงิน
            </vs-button>
            <!-- ยกเลิก -->
            <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action
                ? false
                : true
              " @click="closeRefundPopup" type="filled" size="small" color="dark" icon-pack="feather"
              class="mr-2 my-3">ยกเลิก
            </vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup classContent="popup-example" title="ยืนยันการแก้ไข" :active.sync="popup_otp">
        <br>
        <h3 class="text-center">กรุณากรอก OTP ของท่านเพื่อยืนยัน</h3>
        <br>
        <div class="text-center">

          <vs-input class="w-full mt-4" label="" v-model="otp" name="otp" />

          <br>
          <vs-button @click="updateTournament()" color="success" type="filled">ยืนยัน</vs-button>
        </div>
      </vs-popup>
      <!-- END CANCEL_SYSTEM -->

      <vs-popup classContent="popup-example" title="แก้ไขสถานะเปิดไพ่" :active.sync="popup_clearCard">
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <p>กดยืนยันเพื่อแก้สถานะเปิดไพ่</p>
          </template>
        </vx-input-group>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " @click="updateClearCard()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <vs-popup classContent="popup-example" title="ยืนยัน KYC" :active.sync="popup_kyc">
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <p>กดยืนยัน KYC ลูกค้า</p>
          </template>
        </vx-input-group>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " @click="confirmKyc()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <vs-popup classContent="popup-example" title="สถานะยิงปลา" :active.sync="popup_playfish">
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <p>กดยืนยันเพื่อแก้สถานะยิงปลา</p>
          </template>
        </vx-input-group>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " @click="playfish()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <vs-popup classContent="popup-example" title="แก้ไขโบนัสที่ลูกค้ารับ" :active.sync="popup_updateBonus">
        <div class="vx-col md:w-2/2 w-full">
          <div v-if="webname.includes('SLOTVVIP888')" class="mt-4">
            <label class="vs-input--label">โบนัสที่ลูกค้ารับ</label>
            <vs-select class="w-full" v-model="bonusID.value">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in bonusListVvip" />
            </vs-select>
          </div>

          <div v-else class="mt-4">
            <label class="vs-input--label">โบนัสที่ลูกค้ารับ</label>
            <vs-select class="w-full" v-model="bonusID.value">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in bonusList" />
            </vs-select>
          </div>
          <div class="mt-4">
            <label class="vs-input--label">ประเภทโบนัสที่ลูกค้ารับ</label>
            <vs-select class="w-full" v-model="bonusType.value">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in bonusTypeList" />
            </vs-select>
          </div>
          <br />
        </div>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " @click="updateBonusMember()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <vs-popup classContent="popup-example" title="สถานะหวย" :active.sync="popup_playlotto">
        <br />
        <vx-input-group class="mb-base">
          <template slot="prepend">
            <p>กดยืนยันเพื่อแก้สถานะหวย</p>
          </template>
        </vx-input-group>
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " @click="playlotto()" color="primary" type="filled">ยืนยัน</vs-button>
      </vs-popup>

      <!-- previous username history -->
      <div v-if="memberData.member_username.includes('CB')">
        <a :href="'/topupwd-history/' + memberData.member_username.replace('CB', '')
          " target="_blank">
          <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
            " class="mr-20 my-5" size="small" icon-pack="feather" icon="icon-check" color="#105455">ดูประวัติฝาก-ถอน
            ย้อนหลัง ({{
              memberData.member_username.replace("CB", "")
            }})</vs-button>
        </a>
        <a :href="'/topupwd-history/' + memberData.member_username.replace('B', '')
            " target="_blank">
          <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
            " class="mr-20 my-5" size="small" icon-pack="feather" icon="icon-check" color="#a33351">ดูประวัติฝาก-ถอน
            ย้อนหลัง ({{
              memberData.member_username.replace("B", "")
            }})</vs-button>
        </a>
      </div>
      <a v-else-if="memberData.member_username.includes('C')" :href="'/topupwd-history/' + memberData.member_username.replace('C', '')
        " target="_blank">
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " class="mr-20 my-5" size="small" icon-pack="feather" icon="icon-check" color="#a21251">ดูประวัติฝาก-ถอน
          ย้อนหลัง ({{
            memberData.member_username.replace("C", "")
          }})</vs-button>
      </a>
      <a v-else-if="memberData.member_username.includes('B')" :href="'/topupwd-history/' + memberData.member_username.replace('B', '')
        " target="_blank">
        <vs-button :disabled="$store.state.AppActiveUser.permissions.user.action ? false : true
          " class="mr-20 my-5" size="small" icon-pack="feather" icon="icon-check" color="#178451">ดูประวัติฝาก-ถอน
          ย้อนหลัง ({{
            memberData.member_username.replace("B", "")
          }})</vs-button>
      </a>

      <vs-list-header title="UserInfo" color="success" icon="description"></vs-list-header>
      <br />
      <p>ยูสเซอร์เนม : {{ memberData.member_username }}</p>
      <vs-divider />
      <p>เบอร์โทร : {{ memberData.member_phone }}</p>
      <vs-divider />
      <p>รหัสผ่าน : {{ memberData.member_password }}</p>
      <vs-divider />
      <p>ไอพีที่ใช้สมัคร : {{ memberData.member_ip }}</p>
      <vs-divider />
      <p>ไอพีเข้าเล่นเกม : {{ memberData.member_lastplay_ip }}</p>
      <vs-divider />
      <p>
        ชื่อ - นามสกุล : {{ memberData.member_name }}
        {{ memberData.member_surname }}
      </p>
      <vs-divider />
      <p>เลขบัญชี : {{ memberData.member_bank_number }}</p>
      <vs-divider />
      <p>ธนาคาร : {{ memberData.member_bank_type }}</p>
      <vs-divider />
      <p>ไลน์ : {{ memberData.member_line }}</p>
      <vs-divider />
      <p>
        วันที่สมัคร :
        {{
          moment(memberData.member_register_date).format("YYYY-MM-DD HH:mm:ss")
        }}
      </p>
      <vs-divider />
      <p>
        วันเกิด:
        {{
          memberData.member_birthdate
          ? moment(memberData.member_birthdate).format("YYYY-MM-DD")
          : "-"
        }}
      </p>
      <vs-divider />
      <p class="text-success">
        สถานะการเชื่อมต่อ LINE :
        <span v-if="memberData.member_line_user_id !== null">เชื่อมต่อแล้ว</span><span v-else
          class="text-warning">ไม่ได้เชื่อมต่อ</span>
      </p>
      <vs-divider />
      <p class="text-primary">
        ผู้ที่แนะนำมา ขั้นที่ 1 : {{ memberData.member_aff }}
      </p>
      <vs-divider />
      <p class="text-success">
        ผู้ที่แนะนำมา ขั้นที่ 2 : {{ memberData.member_aff2 }}
      </p>
      <vs-divider />
      <p class="text-success">ระดับบัญชีลูกค้า : {{ max_deposit }}</p>
      <vs-divider />
      <p class="text-warning">
        บัญชีที่ลูกค้าเห็น (scb/kbank) : {{ memberDatabank.bank_for }}
        {{ memberDatabank.bank_fullname }} {{ memberDatabank.bank_number }}
        {{ memberDatabank.bank_reserve }}
      </p>
      <p class="text-warning">
        บัญชีที่ลูกค้าเห็น (bay) : {{ memberDatabankbay.bank_for }}
        {{ memberDatabankbay.bank_fullname }}
        {{ memberDatabankbay.bank_number }} {{ memberDatabankbay.bank_reserve }}
      </p>
      <br />
      <vs-list-header title="Summary" color="blue" icon="swap_horiz"></vs-list-header>
      <p>
        ยอดฝาก :
        {{ currency(parseFloat(memberData.member_topup).toFixed(2)) }} บาท
      </p>
      <p>
        ยอดถอน :
        {{ currency(parseFloat(memberData.member_withdraw).toFixed(2)) }} บาท
      </p>
      <br />
    </vs-list>
  </vx-card>
</template>

<script>
import axios from '../../../axios'

export default {
  data () {
    return {
      webname: process.env.VUE_APP_TITLE,
      turnover: 0,
      bank_see: '',
      sum_deposit: 0,
      sum_withdraw: 0,
      popup_otp: false,
      otp: '',
      max_deposit: '',
      memberData: {},
      memberDatabank: {},
      info_log: '',
      memberDatabankbay: {},
      memberDataSumtransaction: [],
      commentData: [],
      bonusID: {
        text: '',
        value: ''
      },
      bonusType: {
        text: '',
        value: ''
      },
      bonusIDOld: {
        text: '',
        value: ''
      },
      bonusTypeOld: {
        text: '',
        value: ''
      },
      bonusList: [
        {
          text: 'ไม่รับโบนัส',
          value: 0
        },
        {
          text: 'โปรโมชั่น ฝาก 200 รับ 500',
          value: 8
        },
        {
          text: 'โปรโมชั่น ฝาก 500 ได้ 1500',
          value: 9
        },
        {
          text: 'โปรโมชั่น สมัครครั้งแรก 100%',
          value: 10
        },
        {
          text: 'โปรโมชัน ฝากครั้งแรกของวัน รับ 30%',
          value: 11
        },
        {
          text: 'โปรโมชัน ฝาก 500 รับ 600 บาท',
          value: 12
        }
      ],
      bonusListVvip: [
        {
          text: 'ไม่รับโบนัส',
          value: 0
        },
        {
          text: 'สมาชิกใหม่ รับโบนัส 100%',
          value: 8
        },
        {
          text: 'ฝากครั้งแรกของวัน รับโบนัส 30%',
          value: 9
        },
        {
          text: 'โปรโมชั่น 10% รับได้ตลอดทั้งวัน',
          value: 10
        },
        {
          text: 'โปรโมชั่นเทพ SLOT',
          value: 12
        },
        {
          text: 'โปรโมชันเก๊าจิ้งเมืองไทย',
          value: 13
        },
        {
          text: 'โปรโมชั่น 9.9 รับโบนัสเพิ่ม 100%',
          value: 14
        },
        {
          text: 'โปรโมชันคืนยอดเสีย 10%',
          value: 15
        },
        {
          text: 'โปรโมชั่น ฝาก 19 รับ 100',
          value: 16
        },
        {
          text: 'โปรโมชั่น ฝาก 49 รับ 200',
          value: 17
        },
        {
          text: 'โปรโมชั่น ฝาก 99 รับ 400',
          value: 18
        }
      ],
      bonusTypeList: [
        {
          text: 'ไม่รับโบนัส',
          value: 0
        },
        {
          text: 'CASINO',
          value: 1
        },
        {
          text: 'SLOT',
          value: 2
        },
        {
          text: 'SPORT',
          value: 3
        }
      ],
      statusBonus: [
        {
          0: 'ไม่รับโบนัส',
          1: '-',
          2: '-',
          3: 'เปิดไพ่ลุ้นโชค',
          4: 'กงล้อลุ้นโชค',
          5: 'โบนัสเครดิตฟรี',
          6: 'โบนัสเครดิตฟรี Ranking',
          7: 'Happy Time',
          8: 'ฝาก 200 รับ 500',
          9: 'ฝาก 500 ได้ 1500',
          10: 'โปรโมชั่นสมาชิกใหม่ 100%',
          11: 'โปรโมชันฝากครั้งแรกของวัน รับ 30%',
          12: 'โปรโมชัน ฝาก 500 รับ 600 บาท',
          13: 'โปรโมชั่นสมาชิกใหม่ 60%',
          14: 'โปรโมชั่น9.9 รับเพิ่ม 100%'
        }
      ],
      statusBonus1688SA: [
        {
          0: 'ไม่รับโบนัส',
          1: 'สมาชิคใหม่รับโบนัส 50%',
          2: 'ฝากครั้งแรกของวันรับโบนัส 10%',
          3: 'เปิดไพ่ลุ้นโชค',
          4: 'กงล้อลุ้นโชค',
          5: 'โบนัสเครดิตฟรี',
          6: 'โบนัสเครดิตฟรี Ranking',
          7: 'ลูกค้าเก่ารับโบนัส 150%',
          8: 'ฝาก 200 รับ 500',
          9: 'ฝาก 500 ได้ 1500',
          10: 'โปรโมชั่นสมาชิกใหม่ 100%',
          11: 'โปรโมชันฝากครั้งแรกของวัน รับ 30%',
          12: 'โปรโมชัน ฝาก 500 รับ 600 บาท',
          13: 'โปรโมชั่นสมาชิกใหม่ 60%',
          14: 'โปรโมชั่น9.9 รับเพิ่ม 100%'
        }
      ],
      popup_kyc: false,
      popup_playfish: false,
      popup_clearCard: false,
      popup_updateBonus: false,
      popup_playlotto: false,
      title_name: process.env.VUE_APP_TITLE,
      refundGame: 'joker',
      refundPopupSync: false,
      refundPopupRow: {},
      refundGameList: [
        {
          text: 'joker',
          value: 'joker'
        }
      ]
    }
  },
  methods: {
    // #CANCEL_SYSTEM
    async refundBet (isRefund) {
      const result = await axios
        .post(`/refund/${this.refundGame}/all`, {
          username: this.memberData.member_username,
          isRefund: isRefund ? true : undefined
        })
        .catch(error => {
          this.error = error
        })

      if (!this.error && result.data.success) {
        this.$vs.notify({
          title: 'แก้ไขรายการติด BET สำเร็จ',
          text: result.data.description,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

      } else if (!this.error && !result.data.success) this.$vs.notify({
        title: 'แก้ไขรายการติด BET ไม่สำเร็จ',
        text: result.data.description,
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      else this.$vs.notify({
        title: 'เกิดข้อผิดพลาด',
        text: 'โปรดติดต่อโปรแกรมเมอร์',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })

      this.closeRefundPopup()
    },
    async callOtppopup () {
      this.popup_otp = true
    },
    openRefundPopup (username, gameType) {
      this.refundPopupSync = true
      this.refundPopupRow = {
        username,
        gameType
      }
    },
    closeRefundPopup () {
      this.refundPopupSync = false
      this.refundPopupRow = {}
    },
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    async confirmKyc () {
      await axios
        .post('member/confrimkyc', {
          username: this.$route.params.username
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ยืนยัน KYC ไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async playfish () {
      await axios
        .post('member/playfish', {
          username: this.$route.params.username
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.info_log = 'ทำการแก้ไขสถานะยิงปลา'
        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขสถานะยิงปลาไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async updateClearCard () {
      await axios
        .post('member/clear-card', {
          username: this.$route.params.username
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.info_log = 'ทำการแก้ไขสถานะเปิดไพ่'
        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำการแก้ไขสถานะเปิดไพ่ไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async clearDataInPopup () {
      this.otp = ''
    },
    async updateTournament () {
      this.popup_otp = false
      await axios
        .post('member/tournament', {
          username: this.$route.params.username,
          token: this.otp
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.info_log = 'ทำการลงทะเบียนเกาจิ้งเมืองไทย'
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ลงทะเบียนไม่สำเร็จ',
          text: this.status.info
        })
        setTimeout(() => {
          window.location.reload()
        }, 3500)
      }
    },
    async updateBonusMember () {
      for (let i = 0; i < this.bonusTypeList.length; i++) {
        if (this.bonusTypeOld.value === this.bonusTypeList[i].value) {
          this.bonusTypeOld.text = this.bonusTypeList[i].text
        }
      }
      for (let i = 0; i < this.bonusTypeList.length; i++) {
        if (this.bonusType.value === this.bonusTypeList[i].value) {
          this.bonusType.text = this.bonusTypeList[i].text
        }
      }
      for (let i = 0; i < this.bonusList.length; i++) {
        if (this.bonusIDOld.value === this.bonusList[i].value) {
          this.bonusIDOld.text = this.bonusList[i].text
        }
      }
      for (let i = 0; i < this.bonusList.length; i++) {
        if (this.bonusID.value === this.bonusList[i].value) {
          this.bonusID.text = this.bonusList[i].text
        }
      }
      this.info_log = `${this.bonusIDOld.text} (${this.bonusTypeOld.text}) > ${this.bonusID.text} (${this.bonusType.text}) `

      await axios
        .post('member/updatebonus', {
          username: this.$route.params.username,
          bonusID: this.bonusID.value,
          bonusType: this.bonusType.value
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขโบนัสไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async updateaff () {
      await axios
        .post('member/updateaff', {
          username: this.memberData.member_username,
          phone: this.memberData.member_phone
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'อัพเดทรายชื่อแนะนำเพื่อนไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async playlotto () {
      await axios
        .post('member/lotto', {
          username: this.$route.params.username
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.info_log = 'แก้ไขสถานะหวย'

        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'แก้ไขสถานะหวยไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async syncline () {
      await axios
        .post('member/syncline', {
          username: this.$route.params.username
        })
        .then(response => (this.status = response.data))
      if (this.status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: this.status.info
        })
        this.info_log = 'ทำการยกเลิกลงทะเบียนไลน์'

        this.fetchData()
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          // title: 'แก้ไขสถานะยิงปลาไม่สำเร็จ',
          text: this.status.info
        })
        this.fetchData()
      }
    },
    async editWinLose () {
      await axios
        .get(`member/${this.$route.params.username}/correctWinLose`, {})
        .then(response => (this.status = response.data))
      if (this.status.success === true) {
        this.$vs.notify({
          time: 3000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ทำรายการสำเร็จ',
          text: this.status.message
        })
        this.info_log = 'ทำการปรับยอดได้เสียกงล้อ'

      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: this.status.message
        })
      }
    },
    async popupkyc () {
      this.popup_kyc = true
    },
    async popupplayfish () {
      this.popup_playfish = true
    },
    async popupClearCard () {
      this.popup_clearCard = true
    },
    async popupplaylotto () {
      this.popup_playlotto = true
    },
    async updatebonus () {
      this.popup_updateBonus = true
    },
    async closeAllPopups () {
      this.popup_kyc = false
      this.popup_playfish = false
      this.popup_updateBonus = false
      this.popup_playlotto = false
    },
    async fetchData () {
      this.closeAllPopups()

      await axios
        .get(`member/getComment/${this.$route.params.username}`)
        .then(response => (this.commentData = response.data))

      await axios
        .get(`/member/${this.$route.params.username}`)
        .then(response => (this.memberData = response.data))

      await axios
        .get(`member/getBonusInfo/${this.memberData.member_bonus}`)
        .then(response => (this.memberBonusInfo = response.data.data))

      this.bonusID.value = this.memberData.member_bonus
      this.bonusType.value = this.memberData.member_bonus_type
      this.bonusIDOld.value = this.memberData.member_bonus
      this.bonusTypeOld.value = this.memberData.member_bonus_type

      await axios
        .get(`member/showbank/${this.$route.params.username}`)
        .then(response => (this.memberDatabank = response.data))

      await axios
        .get(`member/showbankbay/${this.$route.params.username}`)
        .then(response => (this.memberDatabankbay = response.data))

      if (this.memberDatabank.bank_reserve === false) {
        this.memberDatabank.bank_reserve = '(บัญขีหลัก)'
      } else if (this.memberDatabank.bank_reserve === true) {
        this.memberDatabank.bank_reserve = '(บัญขีสำรอง)'
      }

      if (this.memberDatabankbay.bank_reserve === false) {
        this.memberDatabankbay.bank_reserve = '(บัญขีหลัก)'
      } else if (this.memberDatabankbay.bank_reserve === true) {
        this.memberDatabankbay.bank_reserve = '(บัญขีสำรอง)'
      }

      if (
        this.memberData.member_max_deposit >= 0 &&
        this.memberData.member_max_deposit <= 9999
      ) {
        this.max_deposit = 'VIP0'
      } else if (
        this.memberData.member_max_deposit >= 10000 &&
        this.memberData.member_max_deposit <= 19999
      ) {
        this.max_deposit = 'VIP1'
      } else if (
        this.memberData.member_max_deposit >= 20000 &&
        this.memberData.member_max_deposit <= 29999
      ) {
        this.max_deposit = 'VIP2'
      } else if (
        this.memberData.member_max_deposit >= 30000 &&
        this.memberData.member_max_deposit <= 49999
      ) {
        this.max_deposit = 'VIP3'
      } else {
        this.max_deposit = 'VIP4'
      }

      await axios
        .get(`member/sumtransaction/${this.$route.params.username}`)
        .then(response => (this.memberDataSumtransaction = response.data))

      this.memberDataSumtransaction[0] = this.currency(
        this.memberDataSumtransaction[0]
      )
      this.memberDataSumtransaction[1] = this.currency(
        this.memberDataSumtransaction[1]
      )

    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>
