 <template>
  <div id="page-user-list">
    
      <div class="flex flex-wrap items-center">

  
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="vx-row">
            <div class="vx-col w-full">
            </div>
          </div>
          <br>

          <vx-card>

            <div class="export-table">
              <vs-table stripe pagination max-items="20" :data="memberData" search>

                <template slot="header">
                 <h3>ประวัติ โยกเงิน </h3>

                </template>

                <template slot="thead">
              
                  <vs-th >วัน/เวลา</vs-th>
                  <vs-th> ย้ายมาจาก </vs-th>
                  <vs-th> จำนวนเงิน(กระเป๋า XO) </vs-th>
                  <vs-th> จำนวนเงิน(กระเป๋าหลัก) </vs-th>
                  <vs-th> สถานะโยกเงิน XO  </vs-th>
                  <vs-th> สถานะโยกเงิน กระเป๋าหลัก </vs-th>
                </template>

                <template slot-scope="{data}">
                 
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                    <!-- <vs-button radius color="primary" type="flat"   @click="gotomember(data[indextr].member_username)">{{ data[indextr].member_username}}</vs-button> -->
                    <vs-td>{{ data[indextr].log_date_time}}</vs-td>
                    <vs-td>{{ data[indextr].log_trans_from}}</vs-td>
                    <vs-td>{{currency(data[indextr].log_xo_credit)}}</vs-td>                   
                    <vs-td>{{currency(data[indextr].log_credit)}}</vs-td>
                    <vs-td :class="colorstatus" >{{changstatus( data[indextr].log_xo_status)}}</vs-td>
                    <vs-td :class="colorstatus2" >{{changstatus2( data[indextr].log_trans_status)}}</vs-td>
                  </vs-tr>
                  
                </template>

              </vs-table>
            </div>
          </vx-card>

        </div>
      </div>
    
  </div>

</template>
<script>
import vSelect from 'vue-select'
import axios from '../../../axios'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      
      memberData: [],
      colorstatus: '',
      colorstatus2:''
    }
  },
  mounted () {
    axios
      .get(`/member/${  this.$route.params.username}/transfer`)
      .then(response => (this.memberData = response.data))      
  },
  methods:{
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }
    },
    changstatus (status) {
      if (status === false) {
        this.colorstatus = 'text-danger'
        return 'ย้ายข้อมูลไม่สำเร็จ'
      } else {
        this.colorstatus = 'text-success'
        return 'ย้ายข้อมูลสำเร็จ'
      }
    },
    changstatus2 (status) {
      if (status === false) {
        this.colorstatus2 = 'text-danger'
        return 'ย้ายข้อมูลไม่สำเร็จ'
      } else {
        this.colorstatus2 = 'text-success'
        return 'ย้ายข้อมูลสำเร็จ'
      }

    }
  }
}


</script>

<style lang="scss" scooped>
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
</style>
