<template>
  <div>
    <vx-card>
      <h4 class="mb-4">หมายเหตุ</h4>
      <div class="vx-row">
        <div class="vx-col md:w-5/5 w-full mt-2 mx-5">
          <vx-input-group class="mb-base">
            <label for="ีusername"> ชื่อยูสเซอร์ </label>
            <vs-input
              class="mt-2"
              readonly
              v-model.trim="username"
              type="text"
              name="username"
            />
            <template slot="append"> </template>
          </vx-input-group>
          <vx-input-group class="mb-base">
            <label for="message"> หมายเหตุ </label>
            <vs-textarea
              class="mt-2"
              style="resize: none"
              height="300px"
              v-model.trim="comment_message"
              name="message"
            />
            <template slot="append"></template>
          </vx-input-group>
          <vs-input-group class="mb-base">
            <vs-button
              class="mr-5"
              style="float: left"
              icon="send"
              v-on:click="comment"
              color="primary"
              type="filled"
            >
              บันทึก
            </vs-button>
          </vs-input-group>
        </div>
      </div>
      <div class="vx-row"></div>
    </vx-card>
  </div>
</template>

<script>
import axios from '../../../axios'


export default {
  data () {
    return {
      message: '',
      comment_message: '',
      commentData: [],
      log_info: '',
      username: this.$route.params.username
    }
  },
  async mounted () {
    await axios
      .get(`member/getComment/${this.$route.params.username}`)
      .then(response => (this.commentData = response.data))
    this.comment_message = this.commentData[0].comment
  },
  methods: {
    async comment () {
      await axios
        .post('member/updateComment', {
          username: this.username,
          comment : this.comment_message
        }).then(response => (this.comment_data = response.data))
      console.log(this.comment_data)
      if (this.comment_data.status === false) {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: 'ทำรายการไม่สำเร็จ',
          text: this.comment_data.info
        })
        // await this.clearDataInPopup()
      } else {
        // await this.clearDataInPopup()


        this.$vs.notify({
          time: 8000,
          color: 'success',
          position: 'top-right',
          icon: 'check_box',
          title: 'ทำรายการสำเร็จ',
          text: this.comment_data.info
        })
      }
    },
    comparedata () {
      if (this.commentData[0].comment !== this.comment_message) {
        this.log_info += `หมายเหตุ : ${this.commentData[0].comment} => ${this.comment_message}`
      }
    }
  }
}
</script>

<style></style>
