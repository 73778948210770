<template>
  <div>
    <div class="vx-row">
      <div class="vx-col md:w-1/5 w-full">
        <vx-card title="เลือกวันเวลา">
          <br />
          <div>
            <div class="vx-col md:w-1/2 w-full">
              <label>เริ่มต้น: </label>
              <flat-pickr
                :config="configDateTimePicker"
                v-model="before_datetime"
                placeholder="Date Time"
              />
            </div>
            <br />
            <br />
            <div class="vx-col md:w-1/2 w-full">
              <label> สิ้นสุด: </label>
              <flat-pickr
                :config="configDateTimePicker"
                v-model="after_datetime"
                placeholder="Date Time"
              />
            </div>
          </div>
          <br />
          <br />
          <vs-button color="primary" @click="onSelectDateTime()" size="small"
            >ค้นหา</vs-button
          >
          <br />
          <br />
          <br />
        </vx-card>
      </div>
      <div class="vx-col md:w-4/5 w-full">
        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vx-card title="All Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.sum_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.sum_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.sum_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.sum_rolling)) }}
              </h6>
            </vx-card>
          </div>
        </div>
        <div class="vx-row">
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.sa_amount > 0"
          >
            <br />
            <vx-card title="SA Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.sa_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.sa_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.sa_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.sa_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.sexy_amount > 0"
          >
            <br />
            <vx-card title="Sexy Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.sexy_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.sexy_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.sexy_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.sexy_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.km_amount > 0"
          >
            <br />
            <vx-card title="Km Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.km_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.sexy_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.km_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.km_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.wm_amount > 0"
          >
            <br />
            <vx-card title="Wm Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.wm_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.wm_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.wm_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.wm_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.joker_amount > 0"
          >
            <br />
            <vx-card title="Joker Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.joker_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.joker_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.joker_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.joker_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.pg_amount > 0"
          >
            <br />
            <vx-card title="PG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.pg_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.pg_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.pg_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.pg_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.amb_amount > 0"
          >
            <br />
            <vx-card title="Amb Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.amb_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.amb_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.amb_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.spp_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.sbo_amount > 0"
          >
            <br />
            <vx-card title="SBO Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.sbo_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.sbo_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.sbo_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.sbo_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.evo_amount > 0"
          >
            <br />
            <vx-card title="EVO Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.evo_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.evo_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.evo_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.evo_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.spp_amount > 0"
          >
            <br />
            <vx-card title="SPP Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.spp_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.spp_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.spp_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.spp_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.dream_amount > 0"
          >
            <br />
            <vx-card title="DG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.dream_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.dream_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.dream_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.dream_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.mg_amount > 0"
          >
            <br />
            <vx-card title="MG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.mg_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.mg_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.mg_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.mg_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.pp_slot_amount > 0"
          >
            <br />
            <vx-card title="PP SLOT Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.pp_slot_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.pp_slot_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.pp_slot_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.pp_slot_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.pp_casino_amount > 0"
          >
            <br />
            <vx-card title="PP CASINO Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.pp_casino_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.pp_casino_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.pp_casino_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.pp_casino_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.pp_fish_amount > 0"
          >
            <br />
            <vx-card title="PP FISH Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.pp_fish_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.pp_fish_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.pp_fish_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.pp_fish_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.rich_amount > 0"
          >
            <br />
            <vx-card title="RICH 88 Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.rich_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.rich_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.rich_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.rich_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.allbet_amount > 0"
          >
            <br />
            <vx-card title="ALLBET Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.allbet_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.allbet_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.allbet_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.allbet_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.dragongaming_amount > 0"
          >
            <br />
            <vx-card title="DRAGONGAMING Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.dragongaming_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.dragongaming_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.dragongaming_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.dragongaming_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.hotgraph_amount > 0"
          >
            <br />
            <vx-card title="HOTGRAPH Result">
              <h6>
                BetAmount :
                {{ loading(currency(memberDatacard.hotgraph_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.hotgraph_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.hotgraph_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.hotgraph_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.ag_amount > 0"
          >
            <br />
            <vx-card title="AG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.ag_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.ag_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.ag_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.ag_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.xin_amount > 0"
          >
            <br />
            <vx-card title="XIN Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.xin_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.xin_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.xin_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.xin_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.hdg_amount > 0"
          >
            <br />
            <vx-card title="HDG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.hdg_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.hdg_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.hdg_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.hdg_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.tfg_amount > 0"
          >
            <br />
            <vx-card title="TFG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.tfg_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.tfg_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.tfg_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.tfg_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.spg_amount > 0"
          >
            <br />
            <vx-card title="SPG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.spg_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.spg_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.spg_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.spg_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.dgs_amount > 0"
          >
            <br />
            <vx-card title="DGS Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.dgs_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.dgs_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.dgs_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.dgs_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.ug_amount > 0"
          >
            <br />
            <vx-card title="UG Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.ug_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.ug_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.ug_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.ug_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.jili_amount > 0"
          >
            <br />
            <vx-card title="JILI Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.jili_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.jili_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.jili_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.jili_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.mac_amount > 0"
          >
            <br />
            <vx-card title="MAC Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.mac_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.mac_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.mac_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.mac_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.ibc_amount > 0"
          >
            <br />
            <vx-card title="ibc Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.ibc_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.ibc_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.ibc_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.ibc_rolling)) }}
              </h6>
            </vx-card>
          </div>

          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.gc_amount > 0"
          >
            <br />
            <vx-card title="GC Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.gc_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.gc_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.gc_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.gc_rolling)) }}
              </h6>
            </vx-card>
          </div>

                    <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.jer_amount > 0"
          >
            <br />
            <vx-card title="JER Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.jer_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.jer_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.jer_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.jer_rolling)) }}
              </h6>
            </vx-card>
          </div>

                    <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.llk_amount > 0"
          >
            <br />
            <vx-card title="LLK Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.llk_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.llk_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.llk_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.llk_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.qt_amount > 0"
          >
            <br />
            <vx-card title="QT Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.qt_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.qt_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.qt_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.qt_rolling)) }}
              </h6>
            </vx-card>
          </div>
          <div
            class="vx-col md:w-1/3 w-full"
            v-if="memberDatacard.aml_amount > 0"
          >
            <br />
            <vx-card title="AML Result">
              <h6>
                BetAmount : {{ loading(currency(memberDatacard.aml_amount)) }}
              </h6>
              <br />
              <h6>
                Win/Lose :
                <span
                  :class="
                    memberDatacard.aml_winlose > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ loading(currency(memberDatacard.aml_winlose)) }}
                </span>
              </h6>
              <br />
              <h6>
                RollingAmount :
                {{ loading(currency(memberDatacard.aml_rolling)) }}
              </h6>
            </vx-card>
          </div>
        </div>
      </div>
    </div>
    <br />
    <vx-card>
      <vs-table
        :sst="true"
        @change-page="handleChangePage"
        v-model="selected"
        :total="total"
        pagination
        sort
        :noDataText="noDataText"
        :max-items="perPage"
        :data="memberData"
      >
        <template slot="thead">
          <vs-th sort-key="betdatetime">Bet DATE/TIME</vs-th>
          <vs-th sort-key="payoutdatetime">Payout DATE/TIME</vs-th>
          <vs-th sort-key="game_type">Game_Type</vs-th>
          <vs-th sort-key="username">Username</vs-th>
          <vs-th sort-key="gameid">GameID</vs-th>
          <vs-th sort-key="betamount">BetAmount</vs-th>
          <vs-th sort-key="type">type</vs-th>
          <vs-th sort-key="Before">Before</vs-th>
          <vs-th sort-key="winlost">Win/Lose</vs-th>
          <vs-th sort-key="after">After</vs-th>
          <vs-th sort-key="rollingamount">RollingAmount</vs-th>
        </template>

        {{ memberData }}
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].timestamp">
              {{
                moment(data[indextr].bet_time)
                  .tz("Asia/Bangkok")
                  .format("YYYY-MM-DD HH:mm:ss")
              }}
            </vs-td>

            <vs-td :data="data[indextr].timestamp">
              {{
                moment(data[indextr].payout_time)
                  .tz("Asia/Bangkok")
                  .format("YYYY-MM-DD HH:mm:ss")
              }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-if="data[indextr].game_type === 'SEXYBCRT'"
              style="color: #ff66cc"
            >
              SEXY
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'KINGMAKER'"
              style="color: #00cd00"
            >
              KM
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'SAGAME'"
              style="color: #ffd700"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'JOKER'"
              style="color: #e699ff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'PG'"
              style="color: #ffd700"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'WM'"
              style="color: #00ffff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'AMB'"
              style="color: #80ff00"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'SBOBET'"
              style="color: #1a1aff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'EVO'"
              style="color: #ff3300"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'SPP'"
              style="color: #ff0000"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'DG'"
              style="color: #ff9933"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'MG'"
              style="color: #99ffcc"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type.includes('PP_')"
              style="color: #00ccff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'RICH88'"
              style="color: #9999ff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'ALLBET'"
              style="color: #ffe100"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'DRAGONGAMING'"
              style="color: #009900"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'HOTGRAPH'"
              style="color: #ffd700"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'AG'"
              style="color: #66ccff"
            >
              {{ data[indextr].game_type }}
            </vs-td>
            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'XIN'"
              style="color: #66ccff"
            >
              {{ data[indextr].game_type }}
            </vs-td>

            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'HUAYDRAGON'"
              style="color: #e699ff"
            >
              {{ data[indextr].game_type }}
            </vs-td>

            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'TFG'"
              style="color: #1a1aff"
            >
              {{ data[indextr].game_type }}
            </vs-td>

            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'SPADEGAMING'"
              style="color: #ff0000"
            >
              {{ data[indextr].game_type }}
            </vs-td>

            <vs-td
              :data="data[indextr].game_type"
              v-else-if="data[indextr].game_type === 'DRAGOONSOFT'"
              style="color: #9999ff"
            >
              {{ data[indextr].game_type }}
            </vs-td>

            <vs-td :data="data[indextr].game_type" v-else>
              <b>{{ data[indextr].game_type }}</b>
            </vs-td>

            <vs-td :data="data[indextr].username">
              {{ data[indextr].username }}
            </vs-td>

            <vs-td :data="data[indextr].gameid">
              {{ splitString(data[indextr].gameid) }}
            </vs-td>

            <vs-td :data="data[indextr].amount">
              {{ currency(data[indextr].amount) }}
            </vs-td>

            <!-- #MANUAL_REFUND_SYSTEM -->
            <vs-td
              :data="data[indextr].type"
              v-if="
                data[indextr].type == 'bet' ||
                data[indextr].type == 'BET' ||
                (data[indextr].type == 'lose' &&
                  data[indextr].game_type == 'WM')
              "
              style="color: #ffd700"
            >
              <vs-button
                :disabled="
                  $store.state.AppActiveUser.permissions.user.action
                    ? false
                    : true
                "
                size="small"
                color="warning"
                @click="openRefundPopup(data[indextr])"
                v-if="
                  Object.keys(MANUAL_REFUND_DICT).includes(
                    data[indextr].game_type
                  )
                "
              >
                {{ data[indextr].type }}
              </vs-button>
              <span v-else>
                {{ data[indextr].type }}
              </span>
            </vs-td>
            <!-- END #MANUAL_REFUND_SYSTEM -->
            <vs-td
              :data="data[indextr].type"
              v-else-if="data[indextr].type == 'win'"
              class="text-success"
            >
              {{ data[indextr].type }}
            </vs-td>
            <vs-td
              :data="data[indextr].type"
              v-else-if="data[indextr].type == 'lose'"
              class="text-danger"
            >
              {{ data[indextr].type }}
            </vs-td>
            <vs-td
              :data="data[indextr].type"
              v-else-if="data[indextr].type == 'cancel'"
              class="text-warning"
            >
              {{ data[indextr].type }}
            </vs-td>
            <vs-td :data="data[indextr].type" v-else style="color: #09a3e2">
              {{ data[indextr].type }}
            </vs-td>

            <vs-td :data="data[indextr].bf_balance">
              {{ currency(data[indextr].bf_balance) }}
            </vs-td>

            <vs-td
              :data="data[indextr].result_amount"
              v-if="data[indextr].result_amount > 0"
              class="text-success"
            >
              <vx-tooltip
                v-if="data[indextr].game_type.includes('PP_')"
                :text="
                  'WIN: ' +
                  currency(
                    parseFloat(data[indextr].amount) +
                      parseFloat(data[indextr].result_amount)
                  )
                "
              >
                {{ currency(data[indextr].result_amount) }}
              </vx-tooltip>
              <span v-else>
                {{ currency(data[indextr].result_amount) }}
              </span>
            </vs-td>
            <vs-td
              :data="data[indextr].result_amount"
              v-else-if="data[indextr].result_amount < 0"
              class="text-danger"
            >
              <vx-tooltip
                v-if="data[indextr].game_type.includes('PP_')"
                :text="
                  'WIN: ' +
                  currency(
                    parseFloat(data[indextr].amount) +
                      parseFloat(data[indextr].result_amount)
                  )
                "
              >
                {{ currency(data[indextr].result_amount) }}
              </vx-tooltip>
              <span v-else>
                {{ currency(data[indextr].result_amount) }}
              </span>
            </vs-td>
            <vs-td :data="data[indextr].result_amount" v-else>
              <vx-tooltip
                v-if="data[indextr].game_type.includes('PP_')"
                :text="
                  'WIN: ' +
                  currency(
                    parseFloat(data[indextr].amount) +
                      parseFloat(data[indextr].result_amount)
                  )
                "
              >
                {{ currency(data[indextr].result_amount) }}
              </vx-tooltip>
              <span v-else>
                {{ currency(data[indextr].result_amount) }}
              </span>
            </vs-td>

            <vs-td :data="data[indextr].af_balance">
              {{ currency(data[indextr].af_balance) }}
            </vs-td>

            <vs-td
              :data="data[indextr].rolling_amount"
              v-if="data[indextr].rolling_amount > 0"
            >
              {{ currency(data[indextr].rolling_amount) }}
            </vs-td>
            <vs-td
              :data="data[indextr].rolling_amount"
              v-else-if="data[indextr].rolling_amount < 0"
            >
              {{ currency(data[indextr].rolling_amount * -1.0) }}
            </vs-td>
            <vs-td :data="data[indextr].rolling_amount" v-else>
              {{ currency(data[indextr].rolling_amount) }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!-- #MANUAL_REFUND_SYSTEM -->
      <vs-popup
        title="โปรดยืนยันการคืนเงินติด BET"
        :active.sync="refundPopupSync"
      >
        <div class="p-2 px-3">
          <h1 class="my-3 text-primary">
            คืนเงินติด BET เกม: {{ refundPopupRow.game_type }}
          </h1>
          <h2 class="text-success my-4">
            Username: {{ refundPopupRow.username }}
          </h2>
          <h2 class="text-warning my-4">
            Game ID: {{ refundPopupRow.gameid }}
          </h2>
          <p class="my-3">
            ระบบจะคืนเครดิตจำนวน
            <big class="text-success">{{
              currency(refundPopupRow.amount)
            }}</big>
            บาท ใหักับลูกค้า
          </p>

          <div class="text-center">
            <vs-button
              :disabled="
                $store.state.AppActiveUser.permissions.user.action
                  ? false
                  : true
              "
              @click="refundBet"
              type="filled"
              size="small"
              color="warning"
              icon-pack="feather"
              class="mr-2 my-3"
              >คืนเงิน
            </vs-button>
            <vs-button
              :disabled="
                $store.state.AppActiveUser.permissions.user.action
                  ? false
                  : true
              "
              @click="closeRefundPopup"
              type="filled"
              size="small"
              color="dark"
              icon-pack="feather"
              class="mr-2 my-3"
              >ยกเลิก
            </vs-button>
          </div>
        </div>
      </vs-popup>
      <!-- END MANUAL_REFUND_SYSTEM -->
    </vx-card>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import moment from 'moment'
import axios from '../../../axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import parallel from 'async/parallel'


export default {
  components: {
    vSelect,
    flatPickr
  },
  data () {
    return {
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      after_datetime: moment
        .tz('Asia/Bangkok')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      searchQuery: '',
      memberData: [],
      memberDatacard: {},
      selected: '',
      // Pagination
      total: 0,
      perPage: 100,
      currentPage: 1,
      noDataText: 'โปรดรอสักครู่ ระบบกำลังดึงข้อมูล...',
      isLoading: true,
      // #MANUAL_REFUND_SYSTEM
      // ใส่ game_type ที่สามารถกด refund ได้
      MANUAL_REFUND_DICT: {
        SAGAME: 'sa',
        KINGMAKER: 'sexy',
        SEXYBCRT: 'sexy',
        WM: 'wm'
      },
      refundPopupSync: false,
      refundPopupRow: {}
    }
  },
  async mounted () {
    await this.fetchData()
  },
  methods: {
    // #MANUAL_REFUND_SYSTEM
    async refundBet () {
      const result = await axios
        .post(
          `/refund/${this.MANUAL_REFUND_DICT[this.refundPopupRow.game_type]}`,
          this.refundPopupRow
        )
        .catch((error) => {
          this.error = error
        })

      if (!this.error && result.data.success) {
        const INFO = `คืนเงินติด BET สำเร็จ ${this.refundPopupRow.game_type}_${this.refundPopupRow.username}_${this.refundPopupRow.gameid}`
        this.$vs.notify({
          title: 'คืนเงินติด BET สำเร็จ',
          text: INFO,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        this.refundPopupRow.type = 'MANUAL_REFUND'
        this.refundPopupRow.result_amount = 0
        this.refundPopupRow.af_balance = this.refundPopupRow.bf_balance
      } else if (!this.error && !result.data.success) this.$vs.notify({
        title: 'คืนเงินติด BET ไม่สำเร็จ',
        text: result.data.description,
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      else this.$vs.notify({
        title: 'เกิดข้อผิดพลาด',
        text: 'โปรดติดต่อโปรแกรมเมอร์',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })

      this.closeRefundPopup()
    },
    openRefundPopup (row) {
      this.refundPopupSync = true
      this.refundPopupRow = row
    },
    closeRefundPopup () {
      this.refundPopupSync = false
      this.refundPopupRow = {}
    },
    currency (amount) {
      amount = (amount * 1.0).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        //return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    },
    async fetchData () {
      const { before_datetime, after_datetime, perPage, currentPage } = this
      const { username } = this.$route.params

      const self = this
      self.memberData = []
      self.noDataText = 'โปรดรอสักครู่ ระบบกำลังดึงข้อมูล...'
      self.isLoading = true

      await parallel([
        async () => {
          return axios
            .get(
              `/member/${username}/pagination/playgameall/${before_datetime}/${after_datetime}/${perPage}/${currentPage}`,
              {
                headers: {
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Expires': '0'
                },
                params: {
                  timestamp: moment()
                }
              }
            )
            .then((result) => {
              if (result.data.rows.length <= 0) self.noDataText = 'ไม่พบประวัติการเล่น'
              else {
                self.memberData = result.data.rows
                self.total = result.data.total
                self.perPage = result.data.perPage
                self.currentPage = result.data.currentPage
              }
            })
          // .catch((err) => console.error(err.message))
        },
        async () => {
          return axios
            .get(
              `/member/${username}/playgamecard/${before_datetime}/${after_datetime}`,
              {
                params: {
                  timestamp: moment()
                }
              }
            )
            .then((result) => {
              self.memberDatacard = result.data
              self.isLoading = false
            })
          // .catch((err) => console.error(err.message))
        }
      ])
    },
    handleChangePage (page) {
      this.currentPage = page

      const { before_datetime, after_datetime, perPage, currentPage } = this
      const { username } = this.$route.params

      const self = this
      self.memberData = []
      self.noDataText = 'โปรดรอสักครู่ ระบบกำลังดึงข้อมูล...'

      axios
        .get(
          `/member/${username}/pagination/playgameall/${before_datetime}/${after_datetime}/${perPage}/${currentPage}`,
          {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0'
            },
            params: {
              timestamp: moment()
            }
          }
        )
        .then((result) => {
          if (result.data.rows.length <= 0) self.noDataText = 'ไม่พบประวัติการเล่น'
          else {
            self.memberData = result.data.rows
            self.total = result.data.total
            self.perPage = result.data.perPage
            self.currentPage = result.data.currentPage
          }
        })
      // .catch((err) => console.error(err.message))
    },
    async onSelectDateTime () {
      await this.fetchData()
    },
    loading (data) {
      if (this.isLoading) return ''
      else return data
    },
    //SPLIT STRING NEW LINE
    splitString (str) {
      if (str.length > 15) {
        const middle = Math.ceil(str.length / 2)
        const s1 = str.slice(0, middle)
        const s2 = str.slice(middle)
        return `${s1}\n${s2}`
      } else {
        return str
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

div.ag-root .ag-cell-focus {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>
